import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import download from '../img/icon-download.svg';
import Img from 'gatsby-image';

export default function Template({ data }) {
  const {
    title,
    description,
    documentsImage,
    documentsZipFile,
    documentsTitle,
    photosImage,
    photosTitle,
    photosZipFile,
    videosImage,
    videosTitle,
    videosZipFile
  } = data.allContentfulPagePress.edges[0].node;
  return (
    <Layout nav="colored-nav">
      <HelmetComponent title={title} description={description} />
      <section id="press-hero">
        <div id="page-sub-header" className="white-space">
          <div className="container">
            <div className="page-sub-header-content">
              <div className="row">
                <div className="col-md-7 col-xs-12">
                  <h1 className="black">{title}</h1>
                  <p
                    className="gray"
                    dangerouslySetInnerHTML={{
                      __html: description.description
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="primary" className="content-area mtn120">
        <main id="main" className="site-main" role="main">
          <section id="section-press" className="two-columns">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-4">
                  <div className="card">
                    <Img fluid={documentsImage.fluid} className="card-img-top" alt="" />
                    <div className="card-body">
                      <p className="card-text">
                        {documentsTitle}
                        <span className="download-btn float-right">
                          <img className="mr10" src={download} alt="" />
                          <a
                            href={documentsZipFile.file.url}
                            download="Glory Reborn Docs"
                            className="text-inherit"
                          >
                            Download
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="card">
                    <Img fluid={photosImage.fluid} className="card-img-top" alt="" />
                    <div className="card-body">
                      <p className="card-text">
                        {photosTitle}
                        <span className="download-btn float-right">
                          <img className="mr10" src={download} alt="" />
                          <a
                            href={photosZipFile.file.url}
                            download="Glory Reborn Photos"
                            className="text-inherit"
                          >
                            Download
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="card">
                    <Img fluid={videosImage.fluid} className="card-img-top" alt="" />
                    <div className="card-body">
                      <p className="card-text">
                        {videosTitle}
                        <span className="download-btn float-right">
                          <img className="mr10" src={download} alt="" />
                          <a
                            href={videosZipFile.file.url}
                            download="Glory Reborn Videos"
                            className="text-inherit"
                          >
                            Download
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </section>
    </Layout>
  );
}

export const pressPageQuery = graphql`
  query PressPage($path: String!) {
    allContentfulPagePress {
      edges {
        node {
          title
          description {
            description
          }
          documentsImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          documentsZipFile {
            file {
              url
            }
          }
          documentsTitle
          photosImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          photosZipFile {
            file {
              url
            }
          }
          photosTitle
          videosImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
          videosZipFile {
            file {
              url
            }
          }
          videosTitle
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
